<template>
    <transition-group name="toast" tag="span" class="toasts-list">
        <kk-alert
            v-for="toast in toasts"
            :key="toast.id"
            class="toast"
            :type="toast.type"
            :text="toast.text"
            :sub-text="toast.subText"
            :sub-link="toast.subLink"
            :layout="toast.layout"
            :close-button-visible="toast.closeButtonVisible"
            @close="$store.commit(`${TOAST_NAMESPACE}/${TOAST_STORE.MUTATIONS.REMOVE_TOAST}`, toast.id)"
        />
    </transition-group>
</template>

<script>

import { kkAlert } from 'vue-components';
import { namespace as TOAST_NAMESPACE, TOAST_STORE } from '@/stores/modules/toast';

export default {
    name: 'toast-handler',

    components: {
        kkAlert,
    },

    data() {
        return {
            TOAST_NAMESPACE,
            TOAST_STORE,
        };
    },

    computed: {
        toasts() {
            return this.$store.state.toast?.toasts;
        },
    },
};
</script>

<style lang="scss" scoped>
.toasts-list {
    position: fixed;
    top: 50px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    min-width: 128px;
    width: 100%;
    max-width: 480px;
    z-index: 3000;

    .toast {
        margin: 0 8px;
        width: fit-content;
    }
}

.toast-enter-active {
  transition: all .3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.toast-leave-active {
  transition: all .3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.toast-enter, .toast-leave-to {
  transform: translateY(-24px);
  opacity: 0;
}
</style>
